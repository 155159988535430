import { createContext, useContext, useState, useEffect } from "react"

// Create the context
const EventFilterContext = createContext()

// Hook to use the EventFilterContext
export const useEventFilter = () => useContext(EventFilterContext)

// Provider component
export const EventFilterProvider = ({ children }) => {
	// Load initial state from localStorage or default to all categories selected
	const initialFilterState = () => {
		const savedFilter = localStorage.getItem("eventFilters")
		if (savedFilter) {
			return JSON.parse(savedFilter)
		}
		return [
			"Attractions",
			"Concerts",
			"Conferences",
			"Expos",
			"Festivals",
			"Fundraisers",
			"Gatherings",
			"Other",
			"Parades",
			"Performances",
			"Premieres",
			"Sales",
			"Shows",
			"Sports",
			"Tastings",
			"Workshops",
		] // Default all categories selected
	}

	const [selectedCategories, setSelectedCategories] =
		useState(initialFilterState)

	// Persist the selectedCategories in localStorage whenever it changes
	useEffect(() => {
		localStorage.setItem("eventFilters", JSON.stringify(selectedCategories))
	}, [selectedCategories])

	return (
		<EventFilterContext.Provider
			value={{ selectedCategories, setSelectedCategories }}
		>
			{children}
		</EventFilterContext.Provider>
	)
}
