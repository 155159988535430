// Standard React Hooks
import { useState, useEffect, useMemo } from "react"

// Firebase and Custom Hooks
import { useCollection } from "../../hooks/useCollection"

// Toast for messaging
import { toast } from "react-toastify"

// Components and Mapbox
import { selectPin } from "../../components/PinHelper"
import EventCard from "../../components/EventCard"
import Spinner from "../../components/Spinner"

// Location context for keeping track of user center on map
import { useLocation } from "../../context/UserLocationContext"

// Import context - Which events are filtered/chosen
import { useEventFilter } from "../../context/EventFilterContext"

import Map, {
	Popup,
	Marker,
	NavigationControl,
	ScaleControl,
	GeolocateControl,
} from "react-map-gl"

// Material UI Components
import {
	BottomNavigation,
	BottomNavigationAction,
	Drawer,
	Button,
	Grid,
	Typography,
} from "@mui/material"

import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar"

// Icon Imports
import MenuIcon from "@mui/icons-material/Menu"

// Import all your pins
import AttractionsPin from "../../assets/Pins/Attractions.svg"
import ConcertsPin from "../../assets/Pins/Concerts.svg"
import ConferencesPin from "../../assets/Pins/Conferences.svg"
import ExposPin from "../../assets/Pins/Expos.svg"
import FestivalsPin from "../../assets/Pins/Festivals.svg"
import FundraisersPin from "../../assets/Pins/Fundraisers.svg"
import GatheringsPin from "../../assets/Pins/Gatherings.svg"
import OtherPin from "../../assets/Pins/Other.svg"
import ParadesPin from "../../assets/Pins/Parades.svg"
import PerformancesPin from "../../assets/Pins/Performances.svg"
import PremieresPin from "../../assets/Pins/Premieres.svg"
import SalesPin from "../../assets/Pins/Sales.svg"
import ShowsPin from "../../assets/Pins/Shows.svg"
import SportsPin from "../../assets/Pins/Sports.svg"
import TastingsPin from "../../assets/Pins/Tastings.svg"
import WorkshopsPin from "../../assets/Pins/Workshops.svg"

// Event types with corresponding pins
const eventTypes = [
	{ name: "Attractions", image: AttractionsPin },
	{ name: "Concerts", image: ConcertsPin },
	{ name: "Conferences", image: ConferencesPin },
	{ name: "Expos", image: ExposPin },
	{ name: "Festivals", image: FestivalsPin },
	{ name: "Fundraisers", image: FundraisersPin },
	{ name: "Gatherings", image: GatheringsPin },
	{ name: "Other", image: OtherPin },
	{ name: "Parades", image: ParadesPin },
	{ name: "Performances", image: PerformancesPin },
	{ name: "Premieres", image: PremieresPin },
	{ name: "Sales", image: SalesPin },
	{ name: "Shows", image: ShowsPin },
	{ name: "Sports", image: SportsPin },
	{ name: "Tastings", image: TastingsPin },
	{ name: "Workshops", image: WorkshopsPin },
]

export default function HomeNew() {
	dayjs.extend(isSameOrAfter)
	dayjs.extend(isSameOrBefore)

	// Mapbox Token
	const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

	const { location, setLocation } = useLocation() // Use context for user's location
	const [popupInfo, setPopupInfo] = useState(null)
	const [viewState, setViewState] = useState({
		latitude: 0,
		longitude: 0,
		zoom: 11,
	})
	const [drawerOpen, setDrawerOpen] = useState(false) // Drawer state

	// Add this state to manage the date range
	const [selectedDateRange, setSelectedDateRange] = useState([null, null])

	// Get the filter selections from context
	const { selectedCategories, setSelectedCategories } = useEventFilter()

	// Load events
	const { documents, error, loading } = useCollection("events")

	// Fetch user location
	useEffect(() => {
		const fetchLocation = async () => {
			if (location) {
				setViewState((prevState) => ({
					...prevState,
					latitude: location.latitude,
					longitude: location.longitude,
				}))
				return
			}
			try {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(success, error)
				} else {
					toast.error("Geolocation not supported/enabled")
					setViewState({ latitude: 39.8283, longitude: -98.5795, zoom: 7 })
				}
			} catch (error) {
				console.error("Error fetching location:", error.message)
				setViewState({ latitude: 39.8283, longitude: -98.5795, zoom: 7 })
			}

			function success(position) {
				const userLocation = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				}
				setViewState({
					latitude: userLocation.latitude,
					longitude: userLocation.longitude,
					zoom: 11,
				})
				setLocation(userLocation)
			}

			function error() {
				setViewState({ latitude: 39.8283, longitude: -98.5795, zoom: 7 })
				toast.error("Unable to retrieve your location")
			}
		}
		fetchLocation()
	}, [location, setLocation])

	// Filter and display pins based on selected categories
	//

	const filteredPins = useMemo(() => {
		if (!documents) return null

		return documents
			.filter((event) => {
				// Check if the category is selected
				const isCategorySelected = selectedCategories.includes(event.category)

				// Check if the event is within the selected date range
				const eventStart = dayjs(event.eventStart.toDate())
				const eventEnd = dayjs(event.eventEnd.toDate())

				// If no date range is selected, show all events
				const isWithinDateRange =
					(!selectedDateRange[0] ||
						eventEnd.isSameOrAfter(selectedDateRange[0])) &&
					(!selectedDateRange[1] ||
						eventStart.isSameOrBefore(selectedDateRange[1]))

				return isCategorySelected && isWithinDateRange
			})
			.map((event) => (
				<Marker
					key={`marker-${event.id}`}
					longitude={event.geolocation.lng}
					latitude={event.geolocation.lat}
					anchor="bottom"
					onClick={(e) => {
						e.originalEvent.stopPropagation()
						setPopupInfo(event)
					}}
				>
					{selectPin(event.category)}
				</Marker>
			))
	}, [documents, selectedCategories, selectedDateRange])

	const onRecenter = (evt) => {
		setViewState(evt.viewState)
		setLocation(evt.viewState)
	}

	const toggleDrawer = (open) => () => {
		setDrawerOpen(open)
	}

	const handleEventTypeSelect = (eventType) => {
		setSelectedCategories((prevSelected) => {
			const isSelected = prevSelected.includes(eventType)
			if (isSelected) {
				return prevSelected.filter((type) => type !== eventType)
			} else {
				return [...prevSelected, eventType]
			}
		})
	}

	const handleDateRangeChange = (newRange) => {
		setSelectedDateRange(newRange) // Update the selected date range when changed
	}

	// Wait for browser to give us user location or default
	if (!location && !viewState.latitude) {
		return (
			<>
				<Spinner message="Searching for your location..." />
			</>
		)
	}

	if (loading) {
		return (
			<>
				<Spinner message="Loading Events" />
			</>
		)
	}

	if (error) {
		return (
			<>
				<div>There was an error downloading event data. Are you offline?</div>
			</>
		)
	}

	return (
		documents && (
			<>
				<Map
					{...viewState}
					mapStyle="mapbox://styles/mapbox/streets-v11"
					mapboxAccessToken={TOKEN}
					style={{ width: "100vw", height: "90vh" }} // Adjusted height to fit BottomNav
					onMove={(evt) => onRecenter(evt)}
				>
					<GeolocateControl position="top-left" />
					<NavigationControl position="top-left" />
					<ScaleControl />
					{filteredPins}
					{popupInfo && (
						<Popup
							anchor="top"
							maxWidth="400px"
							longitude={Number(popupInfo.geolocation.lng)}
							latitude={Number(popupInfo.geolocation.lat)}
							onClose={() => setPopupInfo(null)}
						>
							<EventCard event={popupInfo} />
						</Popup>
					)}
				</Map>

				{/* Bottom Navigation Bar */}

				<BottomNavigation
					showLabels
					sx={{ backgroundColor: "black", color: "white" }} // Set background to black and text color to white
				>
					<BottomNavigationAction
						label="Filter Events"
						icon={<MenuIcon style={{ color: "white" }} />} // Set hamburger menu icon to white
						onClick={toggleDrawer(true)}
					/>
				</BottomNavigation>

				{/* Drawer for Event Type Selection */}
				<Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
					{/* New Grid container to hold the two child grids */}
					<Grid
						container
						spacing={2}
						justifyContent="center"
						alignItems="center" // Center vertically if necessary
						sx={{ padding: 2, backgroundColor: "black" }} // Set background to black for the whole drawer
					>
						{/* First grid: Event Type Pins */}
						<Grid
							item
							xs={12}
							md={4}
							lg={3}
							sx={{
								maxWidth: "400px", // Set a maximum width to the grid of pins
								textAlign: "center", // Center content
								backgroundColor: "black",
								padding: 2, // Add padding around pins grid
							}}
						>
							{/* Make this grid smaller */}
							<Grid
								container
								spacing={2}
								justifyContent="center"
								sx={{ backgroundColor: "black" }} // Pins grid
							>
								{eventTypes.map((event) => (
									<Grid
										item
										xs={3}
										key={event.name}
										onClick={() => handleEventTypeSelect(event.name)}
										sx={{ textAlign: "center", color: "white" }} // Center text and set color to white
									>
										<img
											src={event.image}
											alt={event.name}
											style={{
												width: "50px",
												height: "50px",
												opacity: selectedCategories.includes(event.name)
													? 1
													: 0.25,
												cursor: "pointer",
												transition: "opacity 0.3s ease",
											}}
										/>
										<Typography align="center" sx={{ color: "white" }}>
											{/* White text */}
											{event.name}
										</Typography>
									</Grid>
								))}
							</Grid>
						</Grid>

						{/* Second grid: DateRangeCalendar */}
						<Grid item xs={12} md={8} lg={9}>
							{" "}
							{/* Larger grid for the calendar */}
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateRangeCalendar
									// Add any necessary props here to manage the selected date range
									sx={{
										backgroundColor: "black", // Black background
										color: "white", // White text color
										borderRadius: "8px",
										padding: "16px",
										".MuiPickersCalendarHeader-root": {
											color: "white", // Calendar header text white
										},
										".MuiPickersDay-root": {
											color: "white", // Calendar days white
											"&.Mui-selected": {
												backgroundColor: "#009432", // Change color of selected date
											},
											"&:hover": {
												backgroundColor: "#00772b", // Change hover color of dates
											},
										},
										".MuiTypography-root": {
											color: "white", // Ensure all text is white
										},
									}}
									value={selectedDateRange}
									onChange={handleDateRangeChange}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>

					<div
						style={{
							backgroundColor: "black",
							padding: "16px",
							textAlign: "center",
						}}
					>
						{/* Ensure the surrounding div is black */}
						<Button
							variant="contained"
							onClick={toggleDrawer(false)}
							sx={{ margin: 2 }}
						>
							Close Filter
						</Button>
					</div>
				</Drawer>
			</>
		)
	)
}

// const filteredPins = useMemo(() => {
// 	if (!documents) return null

// 	return documents
// 		.filter((event) => selectedCategories.includes(event.category)) // Filter based on selected categories
// 		.map((event) => (
// 			<Marker
// 				key={`marker-${event.id}`}
// 				longitude={event.geolocation.lng}
// 				latitude={event.geolocation.lat}
// 				anchor="bottom"
// 				onClick={(e) => {
// 					e.originalEvent.stopPropagation()
// 					setPopupInfo(event)
// 				}}
// 			>
// 				{selectPin(event.category)}
// 			</Marker>
// 		))
// }, [documents, selectedCategories])
